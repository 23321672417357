import { defineStore } from 'pinia';
import useHttp from '../src/composables/use-http';
import { useAccountStore, useAccountCreatorStore, useFeatureFlagsStore } from './';

const useCreatorStore = defineStore('creator', {
    state: () => {
        return {
            data: {
                liveUrls: null,
                networks: null
            },
            loadingLiveUrls: false,
            loadingNetworks: false,
            error: null
        };
    },
    actions: {
        async getAllLiveUrls() {
            if (!useFeatureFlagsStore()?.data['live-site-switcher'] || !useAccountCreatorStore().data?.id) {
                return;
            }

            const { http } = useHttp();
            this.loadingLiveUrls = true;

            try {
                const response = await http.get(`/creator/live-urls`);
                this.data.liveUrls = response.data;

                if (!this.getCurrentLiveUrlAccount) {
                    this.data.liveUrls = null;
                }
            } catch (e) {
                this.data.liveUrls = null;
                this.error = 'Sorry, there was a problem retrieving your other live sites.';
            }

            this.loadingLiveUrls = false;
        },

        async getAllSocialNetworks() {
            if (!useAccountCreatorStore().data?.id) {
                return;
            }

            const { http } = useHttp();
            this.loadingNetworks = true;

            try {
                const response = await http.get(`/creator/social-networks`);
                this.data.networks = response.data;
            } catch (e) {
                this.data.networks = null;
                this.error = 'Sorry, there was a problem retrieving your social networks.';
            }

            this.loadingNetworks = false;
        }
    },
    getters: {
        isLoading(state) {
            return state.loadingLiveUrls || state.loadingNetworks;
        },
        isCurrentLiveUrlAccount(state) {
            return (liveUrlAccount) => {
                return state.getCurrentLiveUrlAccount?.brands[0]?.account_id === liveUrlAccount?.brands[0]?.account_id;
            };
        },
        getCurrentLiveUrlAccount(state) {
            return (
                state.data.liveUrls?.find(
                    (liveUrlAccount) =>
                        liveUrlAccount.brands.find((brand) => brand.account_id === useAccountStore().data?.id) || null
                ) || null
            );
        },
        getSocialNetworkById(state) {
            return (id) => state.data.networks?.find((network) => network.id === id) || null;
        },
        getFirstSocialNetwork(state) {
            return (networkId) => state.data.networks?.find((network) => network.network_id === networkId) || null;
        },
        getFirstActiveSocialNetwork(state) {
            return (networkId) =>
                state.data.networks?.find(
                    (network) => network.network_id === networkId && network.access_token_is_active === true
                ) || null;
        },
        getSocialNetwork(state) {
            return (networkId, accountId) => {
                let currentSocialNetwork = null;

                state.data.networks?.forEach((socialNetwork) => {
                    if (socialNetwork.network_id === networkId) {
                        socialNetwork.creator_social_networks.forEach((creatorSocialNetwork) => {
                            if (creatorSocialNetwork.account_id === accountId) {
                                currentSocialNetwork = socialNetwork;
                            }
                        });
                    }
                });

                return currentSocialNetwork;
            };
        },
        getCurrentLiveUrlSocialNetwork() {
            return (networkId) => {
                const currentLiveUrl = this.getCurrentLiveUrlAccount;
                if (!currentLiveUrl) {
                    return null;
                }

                return this.getSocialNetwork(networkId, currentLiveUrl.brands[0].account_id);
            };
        },
        getLiveUrlsMissingSocialNetwork(state) {
            return (networkId, externalId) => {
                const notConnected = [];
                state.data.liveUrls?.forEach((liveUrl) => {
                    if (this.canShareToBrand(liveUrl, networkId, externalId)) {
                        notConnected.push(liveUrl);
                    }
                });

                return notConnected;
            };
        },
        canShareToBrand() {
            return (liveUrl, networkId, externalId, excludeCurrentLiveUrl = true) => {
                const socialNetwork = this.getSocialNetwork(networkId, liveUrl.brands[0].account_id);

                if (excludeCurrentLiveUrl && this.isCurrentLiveUrlAccount(liveUrl)) {
                    return false;
                }

                if (socialNetwork) {
                    if (socialNetwork.access_token_is_active) {
                        return false;
                    } else if (socialNetwork.external_id !== externalId) {
                        return false;
                    }
                }

                return true;
            };
        },
        worksWithMulitpleBrands(state) {
            return state.data.liveUrls?.length > 1;
        }
    }
});

export default useCreatorStore;
